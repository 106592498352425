<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="container mt-4">
        <div class="flex">
          <router-link to="/main" class="mx-4 text-blue-500">
            <i class="fas fa-chevron-left font-semibold"></i>
            {{ $t("back") }}
          </router-link>
        </div>
        <form class="m-4 md:mx-24" @submit="onSubmit">
          <div class="flex items-center mb-4">
            <label class="w-48 font-semibold">{{ $t("edit_date") }}</label>
            <t-input
              class="flex-grow"
              disabled
              :value="$route.params.date"
            ></t-input>
          </div>
          <div class="flex mb-4">
            <label class="w-48 font-semibold">{{ $t("remark") }}</label>
            <t-textarea
              v-model="form.text"
              class="flex-grow"
              rows="10"
            ></t-textarea>
          </div>
          <div class="flex mb-4">
            <label class="w-48 font-semibold">{{
              $t("comment_for_employees")
            }}</label>
            <t-textarea
              v-model="form.textEmployees"
              class="flex-grow"
              rows="10"
            ></t-textarea>
          </div>
          <div class="ml-48">
            <t-button class="flex-grow" variant="primary" :disabled="loading">{{
              $t("edit_save")
            }}</t-button>
          </div>
        </form>
      </div>
    </template>
  </layout>
</template>

<script>
import { mapActions } from "vuex";
import { actions } from "@/constants/state";
import * as _ from "lodash";
import { getCommentByDate } from "@/services/comment-service";
import Layout from "@/components/Layout.vue";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    form: {
      id: null,
      text: null,
      textEmployees: null,
    },
  }),
  async mounted() {
    this.loading = true;
    try {
      const { data } = await getCommentByDate(this.$route.params.date);
      if (data) {
        this.form = {
          ..._.omit(data, ["text_employees"]),
          textEmployees: data.text_employees,
        };
      }
    } catch (error) {
      console.error("DEBUG: Comment Form", error);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      createComment: actions.COMMENTS_CREATE_ACTION,
      updateComment: actions.COMMENTS_UPDATE_ACTION,
    }),
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        !this.form.id
          ? await this.createComment({ ...this.form, date: this.$route.params.date })
          : await this.updateComment(this.form);
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onSubmit", error);
      }
      this.loading = false;
    },
  }
};
</script>
