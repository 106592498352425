<template>
  <section>
    <div class="tabs">
      <div class="tab">
        <div class="border-l-2 border-transparent relative">
          <input
            class="w-full absolute z-10 cursor-pointer opacity-0 h-5 top-6"
            type="checkbox"
            id="chck1"
          />
          <label
            class="
              flex
              justify-between
              items-center
              px-4
              cursor-pointer
              select-none
              tab-label
            "
            for="chck1"
            @click="scrollDown"
          >
            <span class="text-gray-700 text-sm">
              LANGUAGE
            </span>
            <div
              class="
                rounded-full
                border border-grey
                w-7
                h-7
                flex
                items-center
                justify-center
                test
              "
            >
              <!-- icon by feathericons.com -->
              <svg
                aria-hidden="true"
                class=""
                data-reactid="266"
                fill="none"
                height="24"
                stroke="#606F7B"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewbox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </div>
          </label>
          <div class="tab-content">
            <div class="pl-8 pr-8 pb-5text-grey-darkest">
              <ul class="pl-4 mt-2">
                <li class="pb-2 w-full language-item cursor-pointer border-b hover:border-gray-300"
                  v-for="(localeOption, index) in locales" :key="index"
                  @click="onLocaleChange(localeOption)"
                  :class="{ 'text-blue-300': localeOption === locale }">
                  {{ localeOption | upperCase }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@/constants/state";

export default {
  name: 'Locale',
  data: () => ({
    locales: [ 'nl', 'en' ]
  }),
  methods: {
    ...mapActions({
      setLocale: actions.SESSION_SET_LOCALE_ACTION,
    }),
    onLocaleChange(locale) {
      this.setLocale(locale);
      this.$i18n.locale = locale;
    },
    scrollDown() {
      setTimeout(() => {
        var container = document.querySelector(".language-item:last-child");
        container.scrollIntoView({behavior: 'smooth'})
      }, 100);
    }
  },
  computed: {
    ...mapGetters({
      locale: getters.SESSION_LOCALE_GETTER,
    })
  },
  filters: {
    upperCase(text) {
      return text.toUpperCase();
    }
  }
}
</script>
<style lang="scss" scoped>
.tab {
  overflow: hidden;
}
.tab-content {
  max-height: 0;
  transition: all 0.5s;
}

input:checked + .tab-label .test svg {
  transform: rotate(180deg);
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
}
</style>

