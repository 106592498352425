<template>
  <div>
    <loader :show="loading"></loader>
    <!-- Navbar goes here -->
    <nav
      class="lightgray-bg-color shadow-lg h-16 border-b border-gray-300 px-3"
    >
      <div
        class="
          container
          px-2
          md:mx-auto
          flex
          justify-between
          h-full
          items-center
        "
      >
        <div>
          <i class="far fa-calendar text-2xl text-gray-500"></i>
          <router-link to="/main" class="font-semibold text-gray-500 text-xl">
            Rooster
          </router-link>
        </div>
        <div class="flex items-center">
          <button
            type="button"
            @click="showDropdown = !showDropdown"
            class="px-4 py-2"
          >
            <i class="fas fa-bars text-gray-500"></i>
          </button>
        </div>
        <div
          :class="{ hidden: !showDropdown }"
          id="navigation-content"
          class="
            origin-top-right
            absolute
            top-16
            right-0
            w-full
            rounded-md
            shadow-lg
            bg-gray-200
            border-b border-gray-300
          "
        >
          <ul class="container px-2 md:mx-auto mb-16 md:mb-0">
            <li class="py-1" v-for="(menu, index) in menus" :key="index">
              <button
                class="
                  w-full
                  text-gray-700
                  block
                  px-4
                  py-2
                  text-sm text-left
                  border-b
                  hover:border-gray-300
                "
                @click="navigateTo(menu.url)"
              >
                {{ $t(menu.description_key) }}
              </button>
            </li>
            <li><locale></locale></li>
            <li class="py-1">
              <button
                class="
                  w-full
                  text-gray-700
                  block
                  px-4
                  py-2
                  text-sm text-left
                  border-b
                  hover:border-gray-300
                "
                @click="navigateTo('/logout')"
              >
                {{ $t("logout_menu_description") }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <main class="container mx-auto px-3">
      <slot name="content"></slot>
    </main>
  </div>
</template>

<script>
import { getters, actions } from "@/constants/state";
import { getEnabledMenus } from "@/utils/user";
import { mapActions, mapGetters } from "vuex";
import Loader from "./Loader.vue";
import Locale from "./Locale.vue";

export default {
  name: "Layout",
  components: { Loader, Locale },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showDropdown: false,
  }),
  methods: {
    ...mapActions({
      logout: actions.AUTH_LOGOUT_ACTION,
    }),
    async navigateTo(url) {
      if (url === "/logout") {
        await this.logout();
        return;
      }

      this.$router
        .push(url)
        .then(() => (this.showDropdown = false))
        .catch(() => (this.showDropdown = false));
    },
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_USER_GETTER,
    }),
    menus() {
      return getEnabledMenus(this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 5;
}

#navigation-content {
  overflow: auto;
  height: 92vh;
}

main {
  padding-top: 70px;
}
</style>
