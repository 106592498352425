<template>
  <div v-if="show" class="progress-bar-wrapper">
    <div class="progress-bar overflow-hidden h-1 mb-4 text-xs flex rounded bg-blue-500">
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
}
</script>
<style scoped lang="scss">
.progress-bar-wrapper {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  z-index: 999;
  top: 0;
  left: 0;

  .progress-bar {
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes running-progress {
  0% { margin-left: 0px; margin-right: 100%; }
  50% { margin-left: 25%; margin-right: 0%; }
  100% { margin-left: 100%; margin-right: 0; }
}
</style>
